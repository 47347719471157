<template>
  <div class="main">
    <!-- 搜索栏 -->
    <ListToolBar @getList="getList" />
    <!-- 审计管理 -->
    <custem-table ref="table" :tableInfo="tableInfo" :tableLoading="tableLoading" @getList="pageChange" />
  </div>
</template>

<script setup>
import ListToolBar from './components/ListToolBar'
import { AuditManagement } from '@/utils/api'
import { onMounted, reactive, toRaw, inject, ref } from 'vue'
import { template } from "lodash"

const t = inject('t')
const table = ref()
const tableLoading = ref(false)
const searchData = ref({ page: 1 })

// 过滤器
const format_text = (row) => {
  const compiled = template(row.action_text, {
    interpolate: /{([\s\S]+?)}/g
  })
  return compiled(toRaw(row.extra))
}

// 表格字段
const tableInfo = reactive({
  header: [
    { // 编号
      key: 'id',
      label: t('system.id'),
      fixed: 'left',
      sortable: true,
      width: '80px'
    },
    { // 创建时间
      key: 'created_at',
      label: t('table.creation_time'),
      sortable: true,
      width: '180px'
    },
    { // 手机号
      key: 'phone',
      label: t('system.phone_number'),
      width: '160px',
      custem: (data) => {
        return data.created_user ? data.created_user.params.liaison_phone : '-'
      }
    },
    { // 动作
      key: 'action_alias',
      label: t('system.action'),
      width: '220px'
    },
    { // 用户
      key: 'created_user',
      width: '220px',
      label: t('system.user'),
      custem: (data) => {
        return data.created_user ? data.created_user.name : '-'
      }
    },
    { // 说明
      key: 'action_text',
      label: t('system.instructions'),
      custem: (data) => {
        return format_text(data)
      }
    }
  ]
})

// 分页触发改变
const pageChange = (current) => {
  searchData.value.page = current.page
  getList({ ...searchData.value })
}

// 获取审计数据列表
const getList = async (search = null) => {
  tableLoading.value = true
  searchData.value = search ? { ...search } : searchData.value
  table.value.resetCurrent(searchData.value.page)
  try {
    const res = await AuditManagement.AuditList({
      ...searchData.value
    })
    tableInfo.data = res.items
    tableInfo.totalPage = res.total
    tableInfo.pageSize = res.per_page
    tableLoading.value = false
  } catch (error) {
    tableLoading.value = false
  }
}

onMounted(() => {
  getList()
})
</script>